import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from "../../store/web3";
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ERC1155Abi, ERC1155ByteCode } from "../../store/contract";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  setLoader,
  deployDonations,
  getDonations,
} from "../../store/actions/Auth.js";
import "./index.css";
import InputField from "../../components/common/InputField";
import infocircle from "../../assets/img/infocircle.svg";
import { networkId } from "../../store/config.js";

class Deploy1155 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supply: 0,
      uri: "",
      tokenIds: 0,
      erc1155: "",
    };
  }

  async componentDidMount() {
    await this.getContract();
  }

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.props.getDonations({ walletAddress });
  }

  handleEditChange = async (e) =>
    this.setState({ [e.target.name]: e.target.value });

  submitTransfer = async () => {
    try {
      let { supply, tokenIds, uri } = this.state;

      let from = (await web3.currentProvider.enable())[0];

      if (uri == "") {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (!uri.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter base URI`);
        return;
      }

      if (parseInt(supply) == 0) {
        EventBus.publish("error", `Please enter initial supply`);
        return;
      }

      if (parseInt(tokenIds) == 0) {
        EventBus.publish("error", `Please enter token ids`);
        return;
      }

      this.props.setLoader({
        message: "Deployment in Progress...",
        status: true,
      });

      let contract = new web3.eth.Contract(ERC1155Abi);
      let deploy = await contract.deploy({
        data: ERC1155ByteCode,
        arguments: [supply, uri, tokenIds],
      });

      await deploy
        .send({ from })
        .on("transactionHash", (hash) =>
          console.log(`************** deploy contract hash = ${hash}`)
        )
        .on("receipt", async (receipt) => {
          this.props.setLoader({
            message: "Contract Deploy...",
            status: false,
          });
          EventBus.publish(
            "success",
            `Deployed: ${receipt["contractAddress"]}`
          );
          this.setState({ erc1155: receipt["contractAddress"] });
          console.log(
            `************** deploy contract address = `,
            receipt["contractAddress"]
          );
        });
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  copiedAddress = () => EventBus.publish("success", "Contract Address Copied");

  render() {
    let { supply, tokenIds, erc1155, uri } = this.state;

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add">
              <div className="edit-add-title col-12">
                <h1 className="text-dark headTop">Launch a New Token</h1>
                <p className="description">
                  To create an XRC-1155 Token, we need some information from you.
                  Please provide the following details to get started.
                </p>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitTransfer}>
                  <div className="wrap">
                    <InputField
                      type="number"
                      name="supply"
                      value={supply}
                      onChange={this.handleEditChange}
                      label="SUPPLY"
                      required={true}
                    />

                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Token Id(s) Copies Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label>
                      {
                        parseInt(networkId) == 51 ?
                          <a href="https://dhegch1bc76pc.cloudfront.net/" target="_blank">Click here to generate your Base URI</a>
                          :
                          <a href="https://tool.cifiapp.com/" target="_blank">Click here to generate your Base URI</a>
                      }
                    </label>
                    <InputField
                      type="text"
                      name="uri"
                      value={uri || ""}
                      onChange={this.handleEditChange}
                      label="BASE URI"
                      required={true}
                    />
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="number"
                      name="tokenIds"
                      value={tokenIds}
                      onChange={this.handleEditChange}
                      label="Beneficiaries"
                      required={true}
                    />

                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Add Number Of Beneficiaries In Digit</p>
                      </div>
                    </div>
                  </div>
                  <div className="text-left mt-3">
                    <CopyToClipboard text={erc1155} onCopy={this.copiedAddress}>
                      <a className="public-address">{`XRC-1155 Contract Address: ${erc1155}`}</a>
                    </CopyToClipboard>
                  </div>
                  <div className="edit-add-buttons">
                    <Button
                      className="submit-btn"
                      type="submit"
                      onSubmit={this.submitTransfer}
                    >
                      Launch Token
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  deployDonations,
  getDonations,
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(Deploy1155);
