import { PURGE } from "redux-persist";
import { setToken } from "../axios";

var initialState = {
  isLogin: false,
  auth: localStorage.getItem("token"),
  address: localStorage.getItem("token"),
  isLoader: { message: "Please Wait...", status: false },
  registerData: [],
  kycData: [],
  kynStatus: false,
  deployedDonations: "",
  erc1155: "",
  userData: [],
  donationContracts: [],
  donationNames: [],
  uriData: [],
  kycStatus: false,
  isInfoModal: false,
  isStatusModal: false,
  isDeployModal: false,
};


const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    /*========== LOGIN REDUCERS ============= */

    case "LOGIN":
      localStorage.setItem("token", payload);
      return {
        ...state,
        address: payload,
        auth: payload,
        isLogin: !state.isLogin,
      };

    // case 'SET_LOGIN_DATA':
    //   setToken(payload['token']);
    //   localStorage.setItem('token', payload['token']);
    //   return {
    //     ...state,
    //     auth: payload.token,
    //   };

    // case 'TOGGLE_LOGIN':
    //   return {
    //     ...state,
    //     isLogin: !state.isLogin,
    //   };

    case "IS_LOGIN_DISABLED":
      return {
        ...state,
      };

    case "LOGOUT":
      setToken();
      localStorage.removeItem("token");
      return {
        ...state,
        address: "",
        auth: "",
        isLogin: !state.isLogin,
      };

    case "SET_ADDRESS":
      return {
        ...state,
        address: payload,
      };

    /*========== LOADER REDUCERS ============= */

    case "SET_LOADER":
      return {
        ...state,
        isLoader: payload,
      };

    /*========== PRE-REGISTER REDUCERS ============= */

    case "SET_TAX_DATA":
      return {
        ...state,
        registerData: payload,
      };

    case "TOGGLE_INFO_MODAL":
      return {
        ...state,
        isInfoModal: payload,
      };

    /*========== APPROVE DROPS REDUCERS ============= */
    case "SET_KYC_STATUS":
      return {
        ...state,
        kycStatus: payload,
      };

    case "SET_KYN_STATUS":
      return {
        ...state,
        kynStatus: payload,
      };

    case 'SET_KYC':
      return {
        ...state,
        kycData: payload
      }

    case "SET_USER":
      return {
        ...state,
        userData: payload,
      };

    case "SET_URI_DATA":
      return {
        ...state,
        uriData: payload,
      };

    case 'TOGGLE_STATUS_MODAL':
      return {
        ...state,
        isStatusModal: payload
      }

    /*========== DEPLOY CONTRACT REDUCERS ============= */
    case 'SET_DONATIONS':
      return {
        ...state,
        deployedDonations: payload
      }

    case 'SET_1155':
      return {
        ...state,
        erc1155: payload
      }

    case "SET_ALL_DONATIONS":
      return {
        ...state,
        donationContracts: payload,
      };

    case "SET_DONATIONS_NAMES":
      return {
        ...state,
        donationNames: payload,
      };

    case 'TOGGLE_DEPLOY_MODAL':
      return {
        ...state,
        isDeployModal: payload
      }

    default:
      return state;
  }
};

export default Auth;
