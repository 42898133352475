import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { validate } from "wallet-address-validator";
import { setLoader, deployDonations, getDonations } from "../../store/actions/Auth.js";
import { DonactionsAbi, DonactionsByteCode } from "../../store/contract";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField";
import infocircle from "../../assets/img/infocircle.svg";
import { CIFIToken } from "../../store/config.js";
import './index.css';

class Deploy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      Name: ""
    };
  };

  async componentDidMount() {
    await this.getContract();
  }

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.props.getDonations({ walletAddress });
  }

  handleEditChange = async (e) => this.setState({ [e.target.name]: e.target.value });

  submitTransfer = async () => {
    try {
      let { address, Name } = this.state;
      let { deployedDonations } = this.props

      let from = (await web3.currentProvider.enable())[0];

      if (deployedDonations.length > 0) {
        EventBus.publish('error', `Donation contract is already deployed!`);
        return;
      };

      if (Name == "") {
        EventBus.publish("error", `Please enter donation name`);
        return;
      }

      if (!Name.replace(/\s/g, '').length) {
        EventBus.publish("error", `Please enter donation name`);
        return;
      }

      if (!Name.match(/[a-zA-Z]/)) {
        EventBus.publish("error", `Donation name must contain alphabets`);
        return;
      }

      if (address == "") {
        EventBus.publish("error", `Please enter the address`);
        return;
      }

      if (!validate(address, "ETH")) {
        EventBus.publish("error", `Please provide valid address`);
        return;
      }

      if (CIFIToken == "") {
        EventBus.publish("error", `CIFI Token address not loaded`);
        return;
      }

      this.props.setLoader({
        message: "Deployment in Progress...",
        status: true,
      });
      
      let contract = new web3.eth.Contract(DonactionsAbi);
      let deploy = await contract.deploy({ data: DonactionsByteCode, arguments: [address, Name, CIFIToken] });

      await deploy.send({ from })
        .on('transactionHash', hash => console.log(`************** deploy contract hash = ${hash}`))
        .on('receipt', async receipt => {
          // await this.props.deployDonations({ walletAddress: from, erc1155Token: address, erc20Token: CIFIToken, donationsContract: receipt['contractAddress'], contractName: Name });
          // this.props.setLoader({
          //   message: "Contract Deploy...",
          //   status: false,
          // });
          console.log("******************** receipt['contractAddress']",receipt['contractAddress']);
          await this.props.deployDonations({ walletAddress: from, erc1155Token: address, erc20Token: CIFIToken, donationsContract: receipt['contractAddress'], contractName: Name });
          this.getContract();
          this.props.setLoader({
            message: "Contract Deploy...",
            status: false,
          });
          // await this.props.getDonations({ walletAddress: from });
          EventBus.publish("success", `Deployed Successfully: ${receipt['contractAddress']}`);
          console.log(`************** deploy contract address = `, receipt['contractAddress'])
        });
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Deploy`);
    }
  };

  render() {
    let { address, Name } = this.state;

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add">
              <div className="edit-add-title col-12">
                <h1 className="text-dark headTop">Deploy Donation Contract</h1>
                <p className="description">Create and deploy a smart contract to manage your donation system on the blockchain for greater transparency and security.</p>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitTransfer}>
                  <div className="wrap">
                    <InputField
                      type="text"
                      name="address"
                      value={address}
                      onChange={this.handleEditChange}
                      label='XRC 1155'
                      required={true}

                    />

                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your XRC-1155 Contract Address Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="Name"
                      value={Name}
                      onChange={this.handleEditChange}
                      label='DONATIONS CONTRACT NAME'
                      required={true}
                    />

                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Donations Cause Name Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="edit-add-buttons mt-4">
                    <Button
                      className="submit-btn"
                      type="submit"
                      onSubmit={this.submitTransfer}
                    >
                      Deploy Donation Contract
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  deployDonations,
  getDonations
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(Deploy);