import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from "../../store/web3.js";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import { validate } from "wallet-address-validator";
import { DonactionsAbi } from "../../store/contract/index.js";
import { setLoader, getDonations } from "../../store/actions/Auth.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField.js";
import arrowlink from "../../assets/img/arrow-link.png";
import range from "../../assets/img/range.png";
import donationtype from "../../assets/img/donation-type.png";
import beneficiaryaddress from "../../assets/img/beneficiary-address.png";
import addtoken from "../../assets/img/add-token-id.png";

import "./index.css";

const DonationsDetailNew = (props) => {

  useEffect(() => {
    getContract();
  }, []);

  async function getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    if (walletAddress) props.getDonations({ walletAddress });
  }

  return (
    <div className="content">
      <div className="main-container mint-new">
        <div className="add-donations-head">
          <div className="left">
            <h2>Add Donation details</h2>

            <p>Enter the details of your donations to be recorded on the blockchain for transparency and security.</p>
          </div>

          <Link to="/home/NewDonationsDetailSteps">
            <button>Add <img src={arrowlink} alt="" /></button>
          </Link>
        </div>

        <div className="add-donations-steps-boxes-wrap">
          <div className="step-box">
            <div className="icon-wrap">
              <img src={range} alt="" />
            </div>

            <div class="step-box-info">
              <span>Step 1</span>

              <h3>Define donations start and end ranges</h3>
            </div>
          </div>

          <div className="step-box">
            <div className="icon-wrap">
              <img src={donationtype} alt="" />
            </div>

            <div class="step-box-info">
              <span>Step 2</span>

              <h3>Set donations cause names</h3>
            </div>
          </div>

          <div className="step-box">
            <div className="icon-wrap">
              <img src={beneficiaryaddress} alt="" />
            </div>

            <div class="step-box-info">
              <span>Step 3</span>

              <h3>Set donations receiving addresses</h3>
            </div>
          </div>

          <div className="step-box">
            <div className="icon-wrap">
              <img src={addtoken} alt="" />
            </div>

            <div class="step-box-info">
              <span>Step 4</span>

              <h3>Set proof of donations NFT token ID(s)</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getDonations,
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonationsDetailNew);
