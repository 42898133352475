import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import { Button, Input, MenuItem } from '@material-ui/core';
import { validate } from "wallet-address-validator";
import { DonactionsAbi } from "../../store/contract";
import { setLoader, getDonations } from "../../store/actions/Auth.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField";
import infocircle from "../../assets/img/infocircle.svg";

import './index.css';

class UpdateDonationsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressToken: "",
      donationTypes: [],
      startAmount: 0,
      endAmount: 0,

      type: "",
      address: "",
      tokenId: 0,
      isPercentage: false
    };
  };

  async componentDidMount() {
    await this.getContract();
  }

  async componentWillReceiveProps({ deployedDonations }) {
    if (deployedDonations.length > 0) {
      let result = await this.dTypes(deployedDonations);
      this.setState({ donationTypes: result });
    }
  }

  dTypes = (deployedDonations) => {
    return new Promise(async (resolve, reject) => {
      let donationContract = new web3.eth.Contract(DonactionsAbi, deployedDonations.toLowerCase());
      let types = await donationContract.methods.getDonationTypes().call();
      console.log("*********** types", types)
      resolve(types);
    })
  }

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.props.getDonations({ walletAddress });
  }

  handleEditChange = async (e) => this.setState({ [e.target.name]: e.target.value });

  togglePercentage = () => {
    this.setState({ isPercentage: !this.state.isPercentage })
  };

  submitToken = async () => {
    try {
      let { deployedDonations } = this.props
      let { addressToken } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (deployedDonations.length == 0) {
        EventBus.publish('error', `Please deploy a donation contract first!`);
        return;
      };

      if (addressToken == "") {
        EventBus.publish('error', `Please enter the Token Address`);
        return;
      };

      if (!validate(addressToken, "ETH")) {
        EventBus.publish("error", `Please provide valid address`);
        return;
      }

      let donationContract = new web3.eth.Contract(DonactionsAbi, deployedDonations);

      /** Approval **/
      this.props.setLoader({
        message: "Update in Progress...",
        status: true,
      });

      // Submit transaction to the blockchain and wait for it to be mined
      await web3.eth
        .sendTransaction({
          from: publicAddress,
          value: 0,
          to: deployedDonations,
          gas: 2000000,
          data: donationContract.methods
            .updateTokenAddress(addressToken)
            .encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          this.setState({ addressToken: "" });
          this.props.setLoader({ status: false });
          EventBus.publish("success", `ERC1155 Updated Successfully!`);
        });
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Update Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  submitDetails = async () => {
    try {
      let { deployedDonations } = this.props
      let { startAmount, endAmount, type, address, tokenId, isPercentage } = this.state;

      let publicAddress = (await web3.currentProvider.enable())[0];

      if (deployedDonations.length == 0) {
        EventBus.publish('error', `Please deploy a donation contract first!`);
        return;
      };

      if (address == "") {
        EventBus.publish('error', `Please enter the Benefeciary Address`);
        return;
      };

      if (startAmount < 0) {
        EventBus.publish('error', `Please enter Donation Start Range`);
        return;
      };

      if (endAmount == 0) {
        EventBus.publish('error', `Please enter Donation End Range`);
        return;
      };

      if (type == "") {
        EventBus.publish('error', `Please select Donation Type`);
        return;
      };

      if (tokenId == 0) {
        EventBus.publish('error', `Please enter the Token Id`);
        return;
      };

      if (!validate(address, "ETH")) {
        EventBus.publish("error", `Please provide valid address`);
        return;
      }

      let donationContract = new web3.eth.Contract(DonactionsAbi, deployedDonations);

      /** Approval **/
      this.props.setLoader({
        message: "Details Submission in Progress...",
        status: true,
      });

      if (!isPercentage) {
        startAmount = web3.utils.toWei(startAmount.toString(), "ether");
        endAmount = web3.utils.toWei(endAmount.toString(), "ether");
      } else {
        startAmount = parseInt(startAmount);
        endAmount = parseInt(endAmount);
      }

      console.log("****** startAmount", startAmount);
      console.log("****** endAmount", endAmount);

      // Submit transaction to the blockchain and wait for it to be mined
      await web3.eth
        .sendTransaction({
          from: publicAddress,
          value: 0,
          to: deployedDonations,
          gas: 2000000,
          data: donationContract.methods
            .updateDonation(startAmount, endAmount, type, address, tokenId, isPercentage)
            .encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          this.setState({ address: "", type: "", tokenId: 0, startAmount: 0, endAmount: 0, isPercentage: false });
          this.props.setLoader({ status: false });
          EventBus.publish("success", `Donation Details Updated Successfully!`);
        });
    } catch (e) {
      console.log("********Error = ", e);
      this.props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  render() {
    let { addressToken, donationTypes, startAmount, endAmount, type, address, tokenId, isPercentage } = this.state;

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add ">
              <div className="edit-add-title col-12">
                <h1 className="text-dark headTop">Update ERC-1155 Token</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitToken}>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="addressToken"
                      value={addressToken}
                      onChange={this.handleEditChange}
                      label='XRC-1155 Token'
                      required={true}
                    />
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your XRC-1155 contract address Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="edit-add-buttons mt-3">
                    <Button
                      className="submit-btn "
                      type="submit"
                      onSubmit={this.submitToken}
                    >
                      Submit
                    </Button>
                  </div>
                </ValidatorForm>
              </div>

              <div className="edit-add-title col-12">
                <h1 className="text-dark headTop mt-3">Update Donation Details</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitDetails}>
                  <div className="wrap">
                    {
                      isPercentage == true ?
                        <InputField
                          type="text"
                          name="startAmount"
                          value={startAmount}
                          onChange={this.handleEditChange}
                          label='START RANGE'
                          required={true}
                        />
                        
                        : <InputField
                          type="text"
                          name="startAmount"
                          value={startAmount}
                          onChange={this.handleEditChange}
                          label='START RANGE'
                          required={true}
                        />
                    }
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Starting Range Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 wrap">
                    {
                      isPercentage == true ?
                        <InputField
                          type="text"
                          name="endAmount"
                          value={endAmount}
                          onChange={this.handleEditChange}
                          label='END RANGE'
                          required={true}
                        />
                        : <InputField
                          type="text"
                          name="endAmount"
                          value={endAmount}
                          onChange={this.handleEditChange}
                          label='END RANGE'
                          required={true}
                        />
                    }
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Ending Range Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      select={true}
                      type='text'
                      name='type'
                      value={type}
                      onChange={this.handleEditChange}
                      label='TYPE'
                      required={true}
                    >

                      {
                        donationTypes.map(item =>
                          <MenuItem value={`${item}`}>{item}</MenuItem>
                        )
                      }
                    </InputField>
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Select Your Donation Type</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="address"
                      value={address}
                      onChange={this.handleEditChange}
                      label='BENEFICIARY ADDRESS'
                      required={true}
                    />
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Donation Beneficiary Address Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="tokenId"
                      value={tokenId}
                      onChange={this.handleEditChange}
                      label='TOKEN ID'
                      required={true}
                    />
                    <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Donation Proof NFT Token Id Here</p>
                      </div>
                    </div>
                  </div>
                  <div className="edit-add-field mt-3 text-left">
                    Amount in Percentage % :
                    <Switch onClick={this.togglePercentage} label="Label" checked={isPercentage} />
                  </div>
                  <div className="edit-add-buttons mt-4">
                    <Button
                      className="submit-btn"
                      type="submit"
                      onSubmit={this.submitDetails}
                    >
                      Submit
                    </Button>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  getDonations
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateDonationsDetail);